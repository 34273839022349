@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
    margin:0px;
    padding: 0px;
    background-color: #ffffff;
    font-family:'Bebas Neue', 'Lato', sans-serif;
}
*{
    box-sizing: border-box;
}
ul{
    list-style: none;
}
a{
    text-decoration: none;
}
button{
    outline: none;
    border: none;
}
input{
    outline: none;
    border: none;
}
.logo img{
    width: 120px;
    transition: 0.6s ease-in-out;
}
.logo.small img{
    width: 50px;
}
#main{
    width:100%;
    height:768px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(/static/media/banner.351d206b.png);
}
nav{
	display: flex;
	justify-content: space-around;
	align-items: center;
	position: fixed;
	left: 0;
	top: 0;
	width:100%;
	z-index: 1;
    background-color: transparent;
    transition: 0.6s ease-in-out;
}
nav.active{
	box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.157);
	background-color: #000;
}
nav ul{
	display: flex;
}
nav ul li a{
    font-family: 'Bebas Neue';
	height:40px;
	line-height: 43px;
	margin: 3px;
	padding: 0px 22px;
	display: flex;
	font-size: 1.2rem;
	text-transform: uppercase;
	font-weight: 500;
	color:#F1F2F4;
    letter-spacing: 1px;
    border-radius: 3px;
    transition: 0.2s ease-in-out;
}

nav.active ul li a{
	color: #f1f1f1;;
}
nav ul li a:hover{
	background-color: #f95800;
    color:#ffffff;
	box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.336);
	transition: all ease 0.2s;
}

nav .menu-btn,
.menu-icon {
  display:none;
}

.header-heading{
	width: 900px;
	position: absolute;
    left: 25%;
    top: 55%;
    text-align: left;
	-webkit-transform: translate(-25%,-55%);
	        transform: translate(-25%,-55%);
}

.header-heading span{
    color: #f95800;
    border-bottom: 2px solid #fff;
}
.header-heading .details{
    font-size: 23px;
    color: #f3f3f3;
}
.header-heading h3{
	font-family:'Bebas Neue';
	font-size: 1.6rem;
	margin:0px;
	letter-spacing: 2px;
	color:#f2f2f2;
}
.header-heading h1{
	font-size: 4rem;
	margin:0px;
	letter-spacing: 2px;
	color:#f1f1f1;
}
.header-btns{
    display: flex;
    margin-top: 40px;
}
.header-btn{
	width:160px;
	height: 50px;
	display: flex;
	justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-family:'Lato';
    background-color: #f95800;
    box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.336);
    border-radius: 5px;
	color:#ffffff;
}
.header-btn:hover{
	background-color: transparent;
    transition: all ease 0.5s;
    color: #3d2514;
    border: 2px solid #f95800;
}

#services{

    min-height:100vh;
    /* box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    padding-top: 100px ;
    background-color: #F2F2F2;
}
#services h1{
    letter-spacing: 2px;
    color: #3d2514;
    font-size: 3rem;
}
#services p{
    letter-spacing: 1px;
    color: #3d2514;
    font-size: 0.8rem;
    margin-top:-15px ;
    margin-bottom:35px ;
}
.a-container{
    display: flex;
    justify-content: center;
    align-content: center;
}
.a-box{
    background-color: #ffffff;
    width: 100%;
    max-width:300px;
    min-width: 150px;
    height: 335px;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    position: relative;
    box-shadow: 0 0 5px #c1c1c1;
}

.a-b-img img{
    width:100%;
    height: 100%;

}
.a-box:hover{
    box-shadow: 2px 2px 12px rgba(184, 98, 0, 0.445);
}
.a-b-text{
    width: 100%;
    height: 40%;
    padding: 40px 20px;
    background-color:#ffffff;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.a-b-text h2{
    color: #3d2514;
    padding-bottom: 20px;
}
.productbox-button{
    width:120px;
	height: 40px;
	display: flex;
	justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-family:'Lato';
    background-color: #f95800;
    box-shadow: 5px 10px 30px rgba(255, 103, 2, 0.336);
    border-radius: 5px;
	color:#ffffff;
}.productbox-button:hover{
	background-color: transparent;
    transition: all ease 0.5s;
    color: #3d2514;
    border: 2px solid #3d2514;
}
#about{
	width:100%;
	height: 100vh;
	padding: 150px 5% 0px 5%;
    background-image: url(/static/media/waves-1.0fadc3a1.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
}

#about h1 {
    letter-spacing: 2px;
    color: #3d2514;
    font-size: 3rem;
}

.about-text{
    margin: 0 auto;
    width: 45%;
    color: #3d2514;
    text-align: center;
    padding-top: 50px;
    
}
.about-text h1{
	font-size: 3.5rem;
	color:#3d2514;
    font-weight: 500;
    margin: 0px;
    padding: 0px;
    font-family: 'Alfa Slab One';
}
.about-text p{
    font-family:'Lato';
    letter-spacing: 1px;
    color: #252525;
    font-size: 1.2rem;
	
}
.about-text button{
    margin-top: 20px;
	width:140px;
	height: 40px;
	display: flex;
	justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-family:'Lato';
    background-color: #bf2222;
    box-shadow: 5px 10px 30px rgba(255, 103, 2, 0.336);
    border-radius: 5px;
	color:#ffffff;
}
.about-text button:hover{
    background-color: transparent;
    transition: all ease 0.5s;
    color: #3d2514;
    border: 2px solid #3d2514;
}
.about-image{
    width: 50%;
}
.about-image img{
  width: 600px;
}

#contact{
    margin-top: 120px;
    background-image: url(/static/media/waves-2.08c9625b.svg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#contact h1{
    letter-spacing: 2px;
    color: #3d2514;
    font-size: 3rem;
}
#contact form{
    width:600px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}
#contact form input,
#contact form textarea{
    width:100%;
    height:50px;
    margin:5px 0px;
    padding: 10px;
    border: none;
    outline: none;
    background-color: #f2f2f2;
    color: #444;
    border-radius: 5px;
}
#contact form textarea{
    height: 150px;
}
#contact form input[type="submit"]{
    height: 45px;
    background: linear-gradient(90deg, #cb4a05 20%,#f95800);
    color: #ffffff;
    text-transform: uppercase;
}

.form-group {
    display: flex!important;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
}

.form-group > .form-input.wm50 input {
    width: 100%;
    min-width: 295px;
}

.form-group > .form-input.wm100 {
    width: 100%;
}

.form-group > .form-input input, 
.form-group > .form-input textarea {
    width: 600px;
}

@media(max-width:620px){
    #contact form{
        width:90%;
    }

    .form-group {
        justify-content: center;
    }

    .form-group > .form-input {
        width: 100%;
    }

    .form-group > .form-input.wm50 input {
        width: 100%;
    }

    .form-input.wm100 > input {
        width: 100%;
    }

}

@media(max-width:1190px){
	#main{
		background-size: 1150px !important;
		
	}
	.header-heading{
		left: 50%;
		top: 50%;
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
        text-align: center;
    }
    .header-heading h2{
        font-size: 1.5rem;
    }
    .header-heading h1{
        font-size: 3rem;
    }
    .header-heading .details{
        font-size: 1rem;
    }
    .pr-heading{
		left: 50%;
		top: 50%;
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
        text-align: center;
    }
    .pr-heading h2{
        font-size: 1.5rem;
    }
    .pr-heading h1{
        font-size: 3rem;
    }
    .pr-heading .details{
        font-size: 1rem;
    }
	.about-image img{
		height:400px;
    }
    #products{
		height:auto;
    }
    .a-container{
		flex-wrap: wrap;
	}
    .a-box{
		flex-grow: 1;
	}
    .a-b-img img{
		object-fit:contain;
    }
    
    

}

@media(max-width:970px){
	#main{
		height: 650px;
	}
	.header-heading{
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%,-50%);
		        transform: translate(-50%,-50%);
    }
    .pr-heading{
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%,-50%);
		        transform: translate(-50%,-50%);
	}
	.about-image{
        display:none;
    }
    .header-btn{
        margin-left: 20%;
        margin-top: 20px;
    }
    .pr-btn{
        margin-left: 20%;
        margin-top: 20px;
    }
	#about{
		justify-content: center;
        text-align: center;
        margin: 0;
	}
	.about-text{
        width: 90%;
        text-align: center;
	}
	.about-text h1{
		font-size:3rem;
    }
    .about-text p{
        width: 100%;
    }
    .about-text button {
        margin-left: 30%;
    }
    #products{
        margin-bottom: 100px;
    }
}
@media(max-width:600px){
	.header-heading{
		width:60%;
    }
    .pr-heading{
		width:60%;
    }
}

@media(max-width:1100px){
    .menu-icon{
        display:block;
    }
    nav{
        justify-content: space-between;
        height: 65px;
        padding: 0px 30px;
        background-color: #000;
    }
    .logo img{
        height: 65px;
        width: auto;
    }
    .header-btns{
        margin: 0;
    }
    .pr-btns{
        margin: 0;
    }
    .menu{
        display:none;
        position:absolute;
        top:65px;
        left:0px;
        background-color:#000;
        border-bottom:4px solid #f95800;
        width:100%;
        padding:0px;
        margin:0px;
    }
    .menu li{
        width:100%;
    }
    nav .menu li a{
        width:100%;
        height:40px;
        justify-content: center;
        align-items: center;
        margin:0px;
        padding: 25px;
        border:1px solid rgba(38,38,38,0.03);
    }
    nav .menu-icon{
        cursor:pointer;
        float:right;
        padding:28px 20px;
        position:relative;
        -webkit-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
    nav .menu-icon .nav-icon{
        background-color:#fff;
        display:block;
        height:2px;
        position:relative;
        transition: background 0.2s ease-out;
        width:24px;
    }
    nav .menu-icon .nav-icon:before,
    nav .menu-icon .nav-icon:after{
        background:rgb(255, 255, 255);
        content:'';
        display:block;
        height:100%;
        position:absolute;
        transition:all ease-out 0.2s;
        width:100%;
    }
    nav .menu-icon .nav-icon:before{
        top:6px;
        background-color: #fff;
    }
    nav .menu-icon .nav-icon:after{
        top:-6px;
        background-color: #fff;
    }
    nav .menu-btn:checked ~ .menu-icon .nav-icon{
        background:transparent;
    }
    nav .menu-btn:checked ~ .menu-icon .nav-icon:before{
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
        top:0;
        background-color: #F2F2F2;
    }
    nav .menu-btn:checked ~ .menu-icon .nav-icon:after{
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        top:0;
        background-color: #f2f2f2;
    }
    nav .menu-btn{
        display:none;
    }
    nav .menu-btn:checked ~ .menu{
        display:block;
    }
}
@media(max-width:400px){
    .a-box{
        width: 190px;
        height: 300px;
    }
    #about {
        height: 100%;
    }
}

.p2 {
    padding-top: 2rem;
}

.p5 {
    padding-top: 5rem;
}

.rs {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.rs-item {
    margin-left: 5px;
    margin-right: 5px;
}

.rs-item > a > img {
    width: 50px;
    height: auto;
}
.App {
  text-align: center;
}


